<template>
  <div>
    <OrderList :customerInfo="this.customerInfo" :addflag="false" :propsStatus="1"></OrderList>
  </div>
</template>

<script>
import PM from '../../api/base/api_pm';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import OrderList from "./list";

export default {
  name: "probuilding",
  components: {
    OrderList
  },
  data() {
    return {
      customerInfo: {}
    }
  },
  // 初始化加载
  mounted() {
      this.init();
  },
  // 方法定义
  methods: {
    init() {

    }
  }
}
</script>